import {EUROPE_LOCALES} from "@/constants";

const urlParams = new URLSearchParams(window.location.search);
const lang = urlParams.has('lang') ? urlParams.get('lang') : '';
const americanLangs = [...EUROPE_LOCALES];
const allLangs = [...americanLangs];

const videoPath = allLangs.includes(lang) ? `fr` : 'en';

const elementsEn = [
    "Station owners can set how power is shared between paired stations with Power Allocation Polices set through the ChargePoint Cloud. These policies are:",
    "1. First Come, First Served",
    "2. Equal Share",
    "3. Fixed Allocation",
];

const elementsFrCa = [
    "Les propriétaires de borne peuvent définir la manière dont la puissance est partagée entre les bornes couplées, les politiques de répartition étant définies via le cloud ChargePoint. Ces différentes politiques sont les suivantes :",
    "1. Premier arrivé, premier servi",
    "2. Partage égal",
    "3. Allocation fixe",
];

const powerAllocationDescriptionFrCa = elementsFrCa.join("<br/>");

const powerAllocationDescriptionEn = elementsEn.join("<br/>");

const descriptionPath = allLangs.includes(lang) ? powerAllocationDescriptionFrCa : powerAllocationDescriptionEn;

export default {
    fleet: {
        scalableSolution: [
            {
                id: 'cable-management-swing-arm',
                stationSide: 'front',
                pointPosition: [55, 120],
                videoUrl: `/assets/video/universal/CPE250_01_HS01_Swing_Arm_Cable_Management.mp4`,
                previewUrl: '',
                title: 'cableManagementSwingArmTitle',
                text: 'cableManagementSwingArmDescr',
                lineHeight: 45,
                lineWidth: 70,
                titlePosition: 'right',
                infoPosition: 'center',
                infoTextStyle: {position: 'absolute', left: '70px', top: '23%', width: '260px'},
                infoVideoStyle: {width: '60%'}
            },
            {
                id: 'charge-point-cloud-services',
                stationSide: 'front',
                pointPosition: [60, 570],
                videoUrl: `/assets/video/universal/CPE250_01_HS02_ChargePoint_Cloud_Application.mp4`,
                previewUrl: '',
                title: 'chargePointTitle',
                text: 'chargePointDescr',
                lineHeight: 0,
                lineWidth: 10,
                titlePosition: 'left',
                infoPosition: 'right',
                infoTextStyle: {position: 'absolute', left: '70px', top: '23%', width: '230px'},
                infoVideoStyle: {width: '75%'}
            },
            {
                id: 'area-lighting',
                stationSide: 'front',
                pointPosition: [170, 570],
                videoUrl: `/assets/video/universal/CPE250_01_HS03_Area_Lighting.mp4`,
                previewUrl: '',
                title: 'areaLightingTitle',
                text: 'areaLightingDescr',
                lineHeight: 0,
                lineWidth: 10,
                titlePosition: 'left',
                infoPosition: 'center',
                infoTextStyle: {position: 'absolute', left: '70px', top: '23%', width: '230px'},
                infoVideoStyle: {width: '25%'}
            },
            {
                id: 'status-display',
                stationSide: 'front',
                pointPosition: [280, 570],
                videoUrl: `/assets/video/universal/CPE250_01_HS04_Status_Display_v02.mp4`,
                previewUrl: '',
                title: 'statusDisplayTitle',
                text: 'statusDisplayDescr',
                lineHeight: 0,
                lineWidth: 10,
                titlePosition: 'left',
                infoPosition: 'center',
                infoTextStyle: {position: 'absolute', left: '70px', top: '23%', width: '230px'},
                infoVideoStyle: {width: '25%'}
            },
            {
                id: 'lcd-screen',
                stationSide: 'front',
                pointPosition: [520, 410],
                videoUrl: `/assets/video/cpe250/${videoPath}/CPE250_01_HS05_LCD_Screen.mp4`,
                previewUrl: '',
                title: 'lcdScreenTitle',
                text: 'lcdScreenDescr',
                lineHeight: 100,
                lineWidth: 170,
                titlePosition: 'left',
                infoPosition: 'right',
                infoTextStyle: {position: 'absolute', left: '70px', top: '23%', width: '230px'},
                infoVideoStyle: {width: '75%'}
            },
            {
                id: 'custom-branded-panels',
                stationSide: 'front',
                pointPosition: [360, 275],
                videoUrl: `/assets/video/universal/CPE250_01_HS06_Custom_Branded_Panels.mp4`,
                previewUrl: '',
                title: 'customBrandedPanelsTitle',
                text: 'customBrandedPanelsDescr',
                lineHeight: 200,
                lineWidth: 225,
                titlePosition: 'right',
                infoPosition: 'center',
                infoTextStyle: {position: 'absolute', left: '70px', top: '23%', width: '230px'},
                infoVideoStyle: {width: '25%'}
            },
            {
                id: 'connectivity',
                stationSide: 'front',
                pointPosition: [680, 720],
                videoUrl: `/assets/video/universal/CPE250_01_HS08_Connectivity.mp4`,
                previewUrl: '',
                title: 'connectivityCPE250Title',
                text: 'connectivityCPE250Descr',
                lineHeight: 0,
                lineWidth: 10,
                titlePosition: 'left',
                infoPosition: 'right',
                infoTextStyle: {position: 'absolute', left: '70px', top: '23%', width: '230px'},
                infoVideoStyle: {width: '27%'}
            },
            {
                id: 'charge-everywhere',
                stationSide: 'front',
                pointPosition: [1080, 355],
                videoUrl: '',
                previewUrl: '/assets/preview/CPE250_01_HS07_Charge_Everywhere.jpg',
                title: 'chargeEverywhereCPE250Title',
                text: 'chargeEverywhereCPE250Descr',
                lineHeight: 110,
                lineWidth: 305,
                titlePosition: 'right',
                infoPosition: 'left',
                infoTextStyle: {position: 'absolute', left: '70px', top: '23%', width: '230px'},
            },
            {
                id: 'waitlist',
                stationSide: 'front',
                pointPosition: [680, 310],
                videoUrl: `/assets/video/cpe250/${videoPath}/CPE250_01_HS09_Waitlist.mp4`,
                previewUrl: '',
                title: 'waitlistTitle',
                text: 'waitlistDescr',
                lineHeight: 200,
                lineWidth: 260,
                titlePosition: 'right',
                infoPosition: 'left',
                infoTextStyle: {position: 'absolute', left: '70px', top: '23%', width: '230px'},
                infoVideoStyle: {width: '27%'}
            }
        ],
        lifetimeCost: [
            {
                id: 'field-replaceable-cables',
                stationSide: 'front',
                pointPosition: [100, 190],
                videoUrl: '/assets/video/universal/CPE250_02_HS01_Field_Replaceable_Cables.mp4',
                previewUrl: '',
                title: 'fieldReplaceableCablesTitle',
                text: 'fieldReplaceableCablesDescr',
                lineHeight: 50,
                lineWidth: 60,
                titlePosition: 'right',
                infoPosition: 'center',
                infoTextStyle: {position: 'absolute', left: '70px', top: '23%', width: '260px'},
                infoVideoStyle: {width: '60%'}
            },
            {
                id: 'liquid-cooling',
                stationSide: 'front',
                pointPosition: [460, 240],
                videoUrl: '/assets/video/universal/CPE250_HS02_Liquid_Cooling_White.mp4',
                previewUrl: '',
                title: 'liquidCoolingTitle',
                text: 'liquidCoolingDescr',
                lineHeight: 130,
                lineWidth: 195,
                titlePosition: 'right',
                infoPosition: 'center',
                infoTextStyle: {position: 'absolute', left: '70px', top: '23%', width: '260px'},
                infoVideoStyle: {width: '30%'}
            },
            {
                id: 'venting',
                stationSide: 'front',
                pointPosition: [770, 570],
                videoUrl: '/assets/video/universal/CPE250_02_HS03_Venting_V2_White.mp4',
                previewUrl: '',
                title: 'ventingTitle',
                text: 'ventingDescr',
                lineHeight: 130,
                lineWidth: 220,
                titlePosition: 'left',
                infoPosition: 'center',
                infoTextStyle: {position: 'absolute', left: '70px', top: '23%', width: '260px'},
                infoVideoStyle: {width: '25%'}
            },
            {
                id: 'station-and-power-modules',
                stationSide: 'front',
                pointPosition: [1020, 570],
                videoUrl: '/assets/video/universal/CPE250_02_HS04_Station_Power_Modules.mp4',
                previewUrl: '',
                title: 'stationAndPowerModulesTitle',
                text: 'stationAndPowerModulesDescr',
                lineHeight: 130,
                lineWidth: 220,
                titlePosition: 'left',
                infoPosition: 'center',
                infoTextStyle: {position: 'absolute', left: '70px', top: '23%', width: '260px'},
                infoVideoStyle: {width: '60%'}
            },
            {
                id: 'small-footprint',
                stationSide: 'front',
                pointPosition: [1170, 130],
                videoUrl: '/assets/video/universal/CPE250_02_HS05_Small_Footprint.mp4',
                previewUrl: '',
                title: 'smallFootprintTitle',
                text: 'smallFootprintDescr',
                lineHeight: 0,
                lineWidth: 10,
                titlePosition: 'right',
                infoPosition: 'center',
                infoTextStyle: {position: 'absolute', left: '70px', top: '23%', width: '260px'},
                infoVideoStyle: {width: '45%'}
            },
            {
                id: 'chargePoint-assure',
                stationSide: 'front',
                pointPosition: [610, 55],
                videoUrl: '',
                previewUrl: '/assets/preview/assure.png',
                title: 'chargePointAssureTitle',
                text: 'chargePointAssureDescr',
                lineHeight: 0,
                lineWidth: 10,
                titlePosition: 'right',
                infoPosition: 'left'
            },
            {
                id: 'support',
                stationSide: 'front',
                pointPosition: [720, 55],
                videoUrl: '',
                previewUrl: '/assets/preview/support.png',
                title: 'supportCPE250Title',
                text: 'supportCPE250Descr',
                lineHeight: 0,
                lineWidth: 10,
                titlePosition: 'right',
                infoPosition: 'left'
            },
        ],
        businessIntegrated: [
            {
                id: 'pairing-functionality',
                stationSide: 'front',
                pointPosition: [50, 410],
                videoUrl: '/assets/video/universal/CPE250_03_HS01_Pairing_Functionality.mp4',
                previewUrl: '',
                title: 'pairingFunctionalityTitle',
                text: 'pairingFunctionalityDescr',
                lineHeight: 40,
                lineWidth: 100,
                titlePosition: 'left',
                infoPosition: 'center',
                infoTextStyle: {position: 'absolute', left: '70px', top: '23%', width: '260px'},
                infoVideoStyle: {width: '42%'}
            },
            {
                id: 'power-sharing',
                stationSide: 'front',
                pointPosition: [730, 410],
                videoUrl: '/assets/video/universal/CPE250_03_HS02_Power_Sharing.mp4',
                previewUrl: '',
                title: 'powerSharingTitle',
                text: 'powerSharingDescr',
                lineHeight: 150,
                lineWidth: 250,
                titlePosition: 'left',
                infoPosition: 'right'
            },
            {
                id: 'station-redundancy',
                stationSide: 'front',
                pointPosition: [100, 220],
                videoUrl: '/assets/video/universal/CPE250_03_HS03_Station_Redundancy.mp4',
                previewUrl: '',
                title: 'stationRedundancyTitle',
                text: 'stationRedundancyDescr',
                lineHeight: 80,
                lineWidth: 145,
                titlePosition: 'right',
                infoPosition: 'left'
            },
            {
                id: 'power-allocation',
                stationSide: 'front',
                pointPosition: [650, 245],
                videoUrl: '/assets/video/universal/CPE250_03_HS04_Power_Allocation.mp4',
                previewUrl: '',
                title: 'powerAllocationTitle',
                text: descriptionPath,
                lineHeight: 110,
                lineWidth: 170,
                titlePosition: 'right',
                infoPosition: 'left'
            },
            {
                id: 'power-management',
                stationSide: 'front',
                pointPosition: [250, 580],
                videoUrl: `/assets/video/cpe250/${videoPath}/CPE250_03_HS05_Power_Management.mp4`,
                previewUrl: '',
                title: 'powerManagementCPE250Title',
                text: 'powerManagementCPE250Descr',
                lineHeight: 0,
                lineWidth: 10,
                titlePosition: 'left',
                infoPosition: 'right'
            }
        ]
    },
    commercial: {
        scalableSolution: [
            //   {
            //     id: 'commercial-cable-management',
            //     stationSide: 'front',
            //     pointPosition: [95, 220],
            //     videoUrl: `/assets/video/commercial/${euOrNaPath}/cableManagement.mp4`,
            //     previewUrl: '',
            //     title: 'cableManagementTitle',
            //     text: 'cableManagementDescr',
            //     lineHeight: 60,
            //     lineWidth: 80,
            //     titlePosition: 'right',
            //     infoPosition: 'center',
            //     infoTextStyle: { position: 'absolute', left: '5%', top: '10%', width: '270px' },
            //     infoVideoStyle: { height: '100%' }
            //   },
            //   {
            //     id: 'commercial-high-power',
            //     stationSide: 'back',
            //     pointPosition: [60, 500],
            //     videoUrl: `/assets/video/commercial/${euOrNaPath}/highPower.mp4`,
            //     previewUrl: '',
            //     title: 'highPowerTitle',
            //     text: 'commercialHighPowerDescr',
            //     lineHeight: 100,
            //     lineWidth: 100,
            //     titlePosition: 'left',
            //     infoPosition: 'left'
            //   },
            //   {
            //     id: 'commercial-modularity',
            //     stationSide: 'back',
            //     pointPosition: [260, 500],
            //     videoUrl: '/assets/video/commercial/universal/modularity.mp4',
            //     previewUrl: '',
            //     title: 'modularityTitle',
            //     text: 'modularityDescr',
            //     lineHeight: 100,
            //     lineWidth: 100,
            //     titlePosition: 'left',
            //     infoPosition: 'left'
            //   },
            //   {
            //     id: 'commercial-scale-power',
            //     stationSide: 'back',
            //     pointPosition: [460, 500],
            //     videoUrl: `/assets/video/commercial/${euOrNaPath}/scalePower.mp4`,
            //     previewUrl: '',
            //     title: 'scalePowerTitle',
            //     text: 'commercialScalePowerDescr',
            //     lineHeight: 100,
            //     lineWidth: 100,
            //     titlePosition: 'left',
            //     infoPosition: 'left'
            //   },
        ],
        lifetimeCost: [
            // {
            //   id: 'commercial-durability',
            //   stationSide: 'front',
            //   pointPosition: [470, 200],
            //   videoUrl: '/assets/video/commercial/universal/durability.mp4',
            //   previewUrl: '',
            //   title: 'durabilityTitle',
            //   text: 'durabilityDescr',
            //   lineHeight: 160,
            //   lineWidth: 220,
            //   titlePosition: 'right',
            //   infoPosition: 'left'
            // },
            // {
            //   id: 'commercial-design-link',
            //   stationSide: 'front',
            //   pointPosition: [700, 150],
            //   videoUrl: `/assets/video/commercial/${euOrNaPath}/design.mp4`,
            //   previewUrl: '',
            //   title: 'designTitle',
            //   text: 'designDescr',
            //   lineHeight: 120,
            //   lineWidth: 160,
            //   titlePosition: 'right',
            //   infoPosition: 'center',
            //   infoTextStyle: { position: 'absolute', right: '10%', top: '23%', width: '250px' },
            //   infoVideoStyle: { height: '100%' }
            // },
            // {
            //   id: 'commercial-power-management',
            //   stationSide: 'back',
            //   pointPosition: [60, 500],
            //   videoUrl: '/assets/video/universal/powerManagement.mp4',
            //   previewUrl: '',
            //   title: 'powerManagementTitle',
            //   text: 'powerManagementDescr',
            //   lineHeight: 100,
            //   lineWidth: 100,
            //   titlePosition: 'left',
            //   infoPosition: 'left'
            // },
            // {
            //   id: 'commercial-power-allocation',
            //   stationSide: 'back',
            //   pointPosition: [260, 500],
            //   videoUrl: '/assets/video/commercial/universal/allocation.mp4',
            //   previewUrl: '',
            //   title: 'allocationTitle',
            //   text: 'allocationDescr',
            //   lineHeight: 100,
            //   lineWidth: 100,
            //   titlePosition: 'left',
            //   infoPosition: 'left'
            // },
            // {
            //   id: 'commercial-design-power',
            //   stationSide: 'back',
            //   pointPosition: [460, 500],
            //   videoUrl: `/assets/video/universal/designPowerBlock.mp4`,
            //   previewUrl: '',
            //   title: 'designTitle',
            //   text: 'designDescr',
            //   lineHeight: 100,
            //   lineWidth: 100,
            //   titlePosition: 'left',
            //   infoPosition: 'center',
            //   infoTextStyle: { position: 'absolute', right: 0, top: '23%', width: '230px' },
            //   infoVideoStyle: { height: '100%' }
            // }
        ],
        businessIntegrated: [
            // {
            //   id: 'commercial-business-cloud',
            //   stationSide: 'front',
            //   pointPosition: [110, -50],
            //   videoUrl: '/assets/video/commercial/universal/businessCloud.mp4',
            //   previewUrl: '',
            //   title: 'businessCloudTitle',
            //   text: 'businessCloudDescr',
            //   lineHeight: 0,
            //   lineWidth: 0,
            //   titlePosition: 'right',
            //   infoPosition: 'left'
            // },
            // {
            //   id: 'commercial-dashboard',
            //   stationSide: 'front',
            //   pointPosition: [120, 780],
            //   videoUrl: '/assets/video/universal/dashboard.mp4',
            //   previewUrl: '',
            //   title: 'dashboardTitle',
            //   text: 'dashboardDescr',
            //   lineHeight: 0,
            //   lineWidth: 0,
            //   titlePosition: 'left',
            //   infoPosition: 'left'
            // },
            // {
            //   id: 'commercial-connectivity',
            //   stationSide: 'front',
            //   pointPosition: [370, -50],
            //   videoUrl: '/assets/video/universal/connectivity.mp4',
            //   previewUrl: '',
            //   title: 'connectivityTitle',
            //   text: 'connectivityDescr',
            //   lineHeight: 0,
            //   lineWidth: 0,
            //   titlePosition: 'right',
            //   infoPosition: 'left'
            // },
            // {
            //   id: 'commercial-branding',
            //   stationSide: 'front',
            //   pointPosition: [650, 280],
            //   videoUrl: '',
            //   previewUrl: `/assets/preview/${euOrNaPath}/branding.jpg`,
            //   title: 'brandingTitle',
            //   text: 'brandingDescr',
            //   lineHeight: 120,
            //   lineWidth: 300,
            //   titlePosition: 'right',
            //   infoPosition: 'center',
            //   infoTextStyle: { position: 'absolute', left: '10%', top: '23%', width: '230px' },
            //   infoVideoStyle: { height: '100%' }
            // },
            // {
            //   id: 'commercial-rewards',
            //   stationSide: 'front',
            //   pointPosition: [870, 310],
            //   videoUrl: '/assets/video/commercial/universal/rewards.mp4',
            //   previewUrl: '',
            //   title: 'rewardsTitle',
            //   text: 'rewardsDescr',
            //   lineHeight: 120,
            //   lineWidth: 320,
            //   titlePosition: 'right',
            //   infoPosition: 'left'
            // }
        ],
        experiencedPartner: [
            // {
            //   id: 'commercial-accessibility',
            //   stationSide: 'front',
            //   pointPosition: [470, 180],
            //   videoUrl: `/assets/video/commercial/${euOrNaPath}/accessibility.mp4`,
            //   previewUrl: '',
            //   title: 'accessibilityTitle',
            //   text: 'accessibilityDescr',
            //   lineHeight: 120,
            //   lineWidth: 200,
            //   titlePosition: 'right',
            //   infoPosition: 'left'
            // },
            // {
            //   id: 'commercial-assure',
            //   stationSide: 'front',
            //   pointPosition: [550, -50],
            //   videoUrl: '',
            //   previewUrl: '/assets/preview/assure.png',
            //   title: 'assureCommercialTitle',
            //   infoTitle: 'assureCommercialInfoTitle',
            //   text: 'assureCommercialDescr',
            //   lineHeight: 0,
            //   lineWidth: 0,
            //   titlePosition: 'right',
            //   infoPosition: 'left'
            // },
            // {
            //   id: 'commercial-support',
            //   stationSide: 'front',
            //   pointPosition: [740, -50],
            //   videoUrl: '',
            //   previewUrl: '/assets/preview/support.png',
            //   title: 'supportTitle',
            //   text: 'supportDescr',
            //   lineHeight: 0,
            //   lineWidth: 0,
            //   titlePosition: 'right',
            //   infoPosition: 'left'
            // },
            // {
            //   id: 'commercial-driver-experience',
            //   stationSide: 'front',
            //   pointPosition: [920, 320],
            //   videoUrl: '/assets/video/commercial/universal/driverExperience.mp4',
            //   previewUrl: '',
            //   title: 'driverExperienceTitle',
            //   text: 'driverExperienceDescr',
            //   lineHeight: 40,
            //   lineWidth: 340,
            //   titlePosition: 'right',
            //   infoPosition: 'left'
            // },
            // {
            //   id: 'commercial-driver-network',
            //   stationSide: 'front',
            //   pointPosition: [120, 780],
            //   videoUrl: '',
            //   previewUrl: `/assets/preview/${euOrNaPath}/driverNetwork.jpg`,
            //   title: 'driverNetworkTitle',
            //   text: 'driverNetworkDescr',
            //   lineHeight: 0,
            //   lineWidth: 0,
            //   titlePosition: 'left',
            //   infoPosition: 'left'
            // }
        ]
    }
}
