<template>
  <div class="footer">
    <div class="footer__tours">
      <div class="footer__tours-scrollbar-wrapper">
        <div class="footer__tours-list">
          <div
            v-for="tour in tours"
            :key="tour"
            class="footer__tour"
            :class="{ active: tour === activeTour }"
            @click="_onClickTour(tour)"
          >
            {{ $t(tour) }}
          </div>
        </div>
      </div>
    </div>
    <div class="footer__logo">
      <img
        src="/assets/images/logo.svg"
        alt="logo"
        class="footer__logo-icon"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    activeTour: {
      type: String,
      default: ''
    },
    tours: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    _onClickTour(tour) {
      this.$emit('click-tour', tour)
    }
  }
}
</script>

<style lang="scss">
@import "@vars";
@import "@mixins";

.footer {
  display: grid;
  grid-template-columns: 130px 1fr 130px;
  grid-template-areas: "....... tours  logo";
  justify-items: center;

  @include media-down($tablet) {
    grid-template-columns: 0 1fr 0;
  }

  &__tours {
    grid-area: tours;
    max-width: 600px;
    overflow: hidden;
    position: relative;
    width: 100%;

    &-scrollbar-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding-top: 12px;

      @include media-down($phone) {
        display: block;
        overflow-x: scroll;

        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    &-list {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @include media-down($phone) {
        margin: 0 auto;
        position: relative;
        width: 324px;
      }

      &::before {
        content: '';
        background: $color-light-gray;
        height: 4px;
        margin: 0 auto;
        position: absolute;
        top: 19px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 140px);

        @include media-down($phone) {
          top: 7px;
          width: calc(100% - 100px);
        }
      }
    }
  }

  &__tour {
    color: #555;
    cursor: pointer;
    font-family: 'GothamNarrowLight', sans-serif;
    font-size: 13px;
    padding-top: 33px;
    position: relative;
    text-align: center;
    transition: color .4s;
    user-select: none;
    width: 155px;

    @include media-down($phone) {
      display: -webkit-box;
      font-size: 11px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      width: 100px;
    }

    &.active {
      font-family: 'GothamNarrowMedium', sans-serif;
    }

    &::before {
      @include size(18px);
      background-color: #7A9CAF;
      border-radius: 50%;
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: background-color .4s;
    }

    &.active::before {
      background-color: $color-orange;
    }
  }

  &__logo {
    grid-area: logo;
    width: 130px;

    @include hide-down($tablet);

    &-icon {
      width: 100%;
    }
  }
}
</style>
