<template>
  <transition
      name="container"
      appear
  >
    <div class="container">
      <Header
          :active-tour="activeTourName"
          class="header"
          @business-type-change="_handleBusinessTypeChange"
      />
      <div
          v-show="canShowContent"
          class="content"
      >
        <tour-item
            v-for="type in businessType"
            v-show="type === activeBusinessType"
            :key="type"
            :type="type"
            :is-active="type === activeBusinessType"
            :spots="activeTourSpots"
            :is-mobile="isMobile"
            :active-resolution="activeResolution"
        />
      </div>
      <div
          v-show="!canShowContent"
          class="cant-show"
      >
        {{ $t('rotateDeviceWarning') }}
      </div>
      <Footer
          :active-tour="activeTourName"
          :tours="toursNames"
          @click-tour="_onClickTour"
      />
    </div>
  </transition>
</template>

<script>
import tours from '@/tours'
import mobile from 'is-mobile'
import Header from './Header'
import Footer from './Footer'
import TourItem from '../tour/TourItem'
import * as _ from 'lodash'

export default {
  name: 'Container',
  components: {
    Header,
    Footer,
    TourItem
  },
  data() {
    return {
      activeBusinessType: 'fleet',
      activeTourName: 'scalableSolution',
      canShowContent: true,
      businessType: ['commercial', 'fleet'],
      isMobile: false,
    }
  },
  computed: {
    activeResolution() {
      return this.isMobile ? 'mobile' : 'desktop'
    },

    toursNames() {
      return Object.keys(tours[this.activeBusinessType])
    },

    activeTourSpots() {
      return tours[this.activeBusinessType][this.activeTourName]
    }
  },
  mounted() {
    this.throttledOnOrientationChange = _.debounce(this._onOrientationChange, 300);
    this._onOrientationChange()
    this.isMobile = mobile({tablet: true});
    window.addEventListener('resize', this.throttledOnOrientationChange)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.throttledOnOrientationChange)
  },
  methods: {
    _onClickTour(tourName) {
      this.activeTourName = tourName
    },

    _handleBusinessTypeChange(value) {
      this.activeBusinessType = value
      this.activeTourName = 'scalableSolution'
    },

    _onOrientationChange() {
      this.$nextTick(() => {
        this.isMobile = mobile({tablet: true});
        this.canShowContent = (window.innerHeight >= 560)
      })
    }
  }
}
</script>

<style lang="scss">
@import "@vars";
@import "@mixins";

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 24px;

  @include media-down($tablet) {
    height: 100%;
  }

  @include media-down($phone) {
    padding: 16px 16px 24px;
  }
}

.header {
  margin-bottom: 65px;

  @include media-down($tablet) {
    margin-bottom: 24px;
  }
}

.content {
  align-items: center;
  display: flex;
  flex: 1;
  margin-bottom: 24px;
  min-height: 0;
}

.cant-show {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin: 0 auto;
}

@keyframes container-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container-enter-active {
  animation: container-in .5s ease;
}

.container-leave-active {
  animation: container-in .5s reverse ease;
}
</style>
