<template>
  <div
    class="spot-item"
    :style="actualSpotPosition"
  >
    <div
      class="spot-item__dot"
      :class="{ 'active': isMouseEnter || isSpotActive }"
      @click="selectSpot"
      @mouseenter="enterSpot"
      @mouseleave="leaveSpot"
    />
    <div
      class="spot-item__line"
      :class="{ 'active': isAnySpotActive }"
      :style="spotLineStyle"
    />
    <div
      class="spot-item__title"
      :class="{
        'active': isAnySpotActive,
        [titlePosition]: true,
        'without-line': !lineHeight || !lineWidth
      }"
      :style="spotTitleStyle"
      @click="selectSpot"
    >
      {{ $t(title) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpotItem',
  props: {
    id: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    position: {
      type: Array,
      default: () => [0, 0]
    },
    titlePosition: {
      type: String,
      default: 'left'
    },
    lineHeight: {
      type: Number,
      default: 0
    },
    lineWidth: {
      type: Number,
      default: 0
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    scale: {
      type: Number,
      default: 1
    },
    isSpotActivated: {
      type: Boolean,
      default: false
    },
    activeSpotId: {
      type: [String, null],
      default: null
    }
  },
  data() {
    return {
      isMouseEnter: false,
      isPhone: window.innerWidth <= 768
    }
  },
  computed: {
    actualSpotPosition() {
      const [top, left] = this.position;
      return {
        top: `${top * this.scale}px`,
        left: `${left * this.scale}px`
      };
    },
    currentLineWidth() {
      return this.lineWidth * this.scale
    },
    currentLineHeight() {
      return this.lineHeight * this.scale
    },
    spotLineStyle() {
      return {
        width: `${this.currentLineWidth}px`,
        height: `${this.currentLineHeight}px`,
        [this.titlePosition]: '16px',
        'background-image': `linear-gradient(
          to bottom ${this.titlePosition === 'left' ? 'right' : 'left'},
          transparent calc(50% - 1px),
          #B6C2CD,
          transparent calc(50% + 1px)
        )`
      };
    },
    spotTitleStyle() {
      return {
        top: this.currentLineHeight ? `${-(this.currentLineHeight + 5)}px` : '2px',
        [this.titlePosition]: this.currentLineWidth ? `${this.currentLineWidth + 35}px` : '21px'
      };
    },
    isAnySpotActive() {
      return this.activeSpotId;
    },
    isSpotActive() {
      return this.id === this.activeSpotId;
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.isPhone = window.innerWidth <= 768;
    });
  },
  methods: {
    selectSpot(event) {
      this.$emit('select-spot', this.id);
      event.stopPropagation();
    },
    enterSpot() {
      this.isMouseEnter = true
      this.isMobile && window.innerWidth <= 1024
        ? this.$emit('select-spot', this.id)
        : this.$emit('enter-spot', this.id);
    },
    leaveSpot() {
      this.isMouseEnter = false
      this.$emit('leave-spot', this.id);
    }
  }
}
</script>

<style lang="scss">
@import "@vars";
@import "@mixins";

.spot-item {
  position: absolute;
  z-index: 2;

  &__dot {
    background: $color-light-orange;
    border: 3px solid #FFFFFF;
    border-radius: 50%;
    box-shadow: 0 0 8px #fff;
    cursor: pointer;
    height: 18px;
    position: relative;
    transition: all .4s;
    width: 18px;

    &.active,
    &:hover {
      border-color: $color-light-orange;
      box-shadow: none;

      &::before {
        opacity: 1;
      }
    }

    &::before {
      border: 2px solid $color-light-orange;
      border-radius: 50%;
      content: '';
      height: 28px;
      left: -10px;
      opacity: 0;
      position: absolute;
      top: -10px;
      transition: opacity .4s;
      width: 28px;
    }
  }

  &__line {
    position: absolute;
    bottom: 16px;
    background-color: transparent;
    background-repeat: no-repeat;
    transition: opacity .4s ease-in-out;

    &.active {
      opacity: 0;
      visibility: hidden;
    }
  }
  &__title {
    color: #0076A8;
    cursor: pointer;
    font-size: 16px;
    min-width: 0;
    overflow-y: visible;
    position: absolute;
    transition: opacity .4s ease-in-out;
    white-space: nowrap;
    user-select: none;
    z-index: 2;

    @include media-down($tablet) {
      font-size: 12px;
      min-width: 105px;
      white-space: normal;
    }

    @include media-down($phone) {
      font-size: 9px;
      max-width: 85px;
      min-width: 80px;
    }

    &.active {
      opacity: 0;
      visibility: hidden;
    }

    &.right {
      &:before {
        left: auto;
        right: -19px;
      }
    }

    &:before {
      display: block;
      content: '';
      border-top: 1px solid #B6C2CD;
      width: 12px;
      position: absolute;
      top: 7px;
      left: -19px;
      transition: opacity .4s ease-in-out;

      @include media-down($phone) {
        top: 7px;
      }
    }
  }
}

.without-line {
  &::before {
    display: none;
  }
}
</style>
