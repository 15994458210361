<template>
  <div class="header">
    <div class="header__left-block">
      <div class="header__title">
        ChargePoint® Express 250
      </div>
      <div class="header__description">
        <p class="header__description-item">
          {{ $t(`subtitle.${activeTour}`) }}
        </p>
      </div>
    </div>
<!--    <div class="header__toggler-wrapper">-->
<!--      <span class="header__toggler-title">-->
<!--        Business Type-->
<!--      </span>-->
<!--      <div class="header__toggler">-->
<!--        <span-->
<!--          title="Commercial"-->
<!--          class="header__toggler-value"-->
<!--          :class="{ 'header__toggler-value_active': !isFleet }"-->
<!--        >-->
<!--          Commercial-->
<!--        </span>-->
<!--        <toggle-button-->
<!--          v-model="isFleet"-->
<!--          :height="19"-->
<!--          :width="38"-->
<!--          :color="{-->
<!--            checked: '#7A9CAF',-->
<!--            unchecked: '#7A9CAF'-->
<!--          }"-->
<!--          class="header__toggler-element"-->
<!--          @change="_handleBusinessTypeChange"-->
<!--        />-->
<!--        <span-->
<!--          title="Fleet"-->
<!--          class="header__toggler-value"-->
<!--          :class="{ 'header__toggler-value_active': isFleet }"-->
<!--        >-->
<!--          Fleet-->
<!--        </span>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    activeTour: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isFleet: true
    }
  },
  methods: {
    _handleBusinessTypeChange() {
      this.$emit('business-type-change', this.isFleet ? 'fleet' : 'commercial');
    }
  }
}
</script>

<style lang="scss">
@import "@vars";
@import "@mixins";

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;

  &__left-block {
    position: relative;

    @include media-down($tablet) {
      margin-right: 50px;
      width: auto;
    }

    @include media-down($phone) {
      margin: 15px;
    }
  }

  &__title {
    color: #e75516;
    font-family: 'GothamNarrowLight', sans-serif;
    font-size: 32px;

    @include media-down($tablet) {
      margin-bottom: 15px;
      width: auto;
    }

    @include media-down($phone) {
      font-size: 17px;
      margin: 0;
    }
  }

  &__description {
    color: #e75516;
    font-family: 'GothamNarrowLight', sans-serif;
    font-size: 24px;
    position: absolute;
    top: calc(85%);
    z-index: 1;

    @include media-down($tablet) {
      position: static;
      margin-top: calc(0% - 21px);
    }

    @include hide-down($phone);

    &-item:not(:last-child) {
      margin-bottom: 30px;

      @include media-down($tablet) {
        margin-bottom: 24px;
      }
    }
  }

  &__toggler {
    display: flex;
    justify-content: space-between;
    margin: 2px 0 0 0;

    &-wrapper {
      align-items: center;
      display: flex;
      margin: 0 85px 0 0;

      @include media-down($tablet) {
        align-items: flex-start;
        flex-direction: column;
        margin: 0;
      }
    }

    &-title {
      color: #000;
      font-family: 'GothamNarrowLight', sans-serif;
      font-size: 18px;
      line-height: 21px;
      margin: 0 23px 0 0;
    }

    &-value {
      color: rgba(0, 0, 0, .3);
      font-size: 18px;
      line-height: 21px;

      @include media-down($phone) {
        font-size: 15px;
      }

      &::after { // хак, чтобы элементы не сдвигались при смене шрифта
        display: block;
        content: attr(title);
        font-family: 'GothamNarrowMedium', sans-serif;
        height: 1px;
        color: transparent;
        overflow: hidden;
        visibility: hidden;
      }

      &_active {
        color: $color-font-dark-gray;
        font-family: 'GothamNarrowMedium', sans-serif;
      }
    }

    &-element {
      margin: 0 10px;
    }
  }
}
</style>
